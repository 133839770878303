<script setup lang="ts">
  // If you want to use it in setup, import from the nuxtApp.
  const { $pwa } = useNuxtApp();

  const showDialog = ref(false);

  const clickReload = () => {
    $pwa?.updateServiceWorker();
    showDialog.value = false;
  };

  onMounted(() => {
    if ($pwa?.needRefresh) {
      showDialog.value = true;
    }
  });
</script>

<template>
  <v-dialog v-model="showDialog" scrim="black" persistent>
    <v-sheet class="dialog py-8 px-10 rounded" color="monochrome-04" align="center">
      <div class="text-center">新しいコンテンツが利用可能です。</div>
      <div class="text-center">更新するボタンをクリックしてください。</div>
      <div class="d-flex justify-space-evenly">
        <v-btn color="main-01" class="button mt-5" @click="clickReload"> 更新する </v-btn>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<style lang="scss" scoped>
  .dialog {
    min-height: 146px;
    min-width: 345px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);

    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    white-space: break-spaces;
  }

  .button {
    min-width: 125px;
    min-height: 40px;
    margin-left: 4px;
    margin-right: 4px;

    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }
</style>
